<template>
  <div class="page">
    <div class="page-title">
      <div class="title-name">发文排查</div>
    </div>
    <div class="content-card">
      <div class="content-right">
        <!-- 富文本按钮组 -->
        <div class="editor-btn-group">
          <el-dropdown class="addKeyWord m-l-4" trigger="click">
            <span class="el-dropdown-link" @click="addWordBank">加入词库</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="openWordBank(1)">错词词库</el-dropdown-item>
              <el-dropdown-item @click.native="openWordBank(2)">敏感词词库</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div @click="content=''"> <i class="el-icon-delete"></i>清空</div>
          <div @click="$main.copyFun(content)"><i class="el-icon-document-copy"></i>复制全文</div>
          <el-upload class="upload-demo" action="#" :show-file-list="false" :http-request="uploadFile">
            <i v-loading="editorLoading" class="el-icon-folder-add"></i>从word导入
          </el-upload>

          <div @click="exportFile"><i class="el-icon-folder-remove"></i>从word导出</div>
          <div class="myKeyWord" @click="goDetail('first')">我的词库</div>
          <span class="fontCont">共 {{fontCount}} 字</span>
        </div>
        <!-- 富文本编辑器 -->
        <quill-editor class='editor' v-model="content" ref="myQuillEditor" :options="editorOption" @change="onEditorChange($event)">
        </quill-editor>
      </div>
      <!-- 校对组按钮 -->
      <div class="content-left">
        <div class="btn-group m-b-16">
          <div class="jiaodui m-b-16" v-loading='checkLoading' @click="submit">立即校对</div>
          <div class="btn-type flex-b-c">
            <div class="tips-all flex-b-c flex-d" @click="changeDisplayList(0)">
              <span>全部提示</span>
              <span>{{all_show.length}}</span>
            </div>
            <div class="tips-list">
              <div class="tip-btn tip-red" :class="{'no-background':btnHasStyle_1}" @click="changeStyle(1)">
                <span>高概率</span><span>{{high_probability.length}}</span>
              </div>
              <div class="tip-btn tip-orange" :class="{'no-background':btnHasStyle_2}" @click="changeStyle(2)">
                <span>低概率</span><span>{{low_probability.length}}</span>
              </div>
              <div class="tip-btn tip-green" :class="{'no-background':btnHasStyle_3}" @click="changeStyle(3)">
                <span>自定义</span><span>{{custom.length}}</span>
              </div>
              <div class="tip-btn tip-blue" :class="{'no-background':btnHasStyle_4}" @click="changeStyle(4)">
                <span>敏感词</span><span>{{sensitive.length}}</span>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="display-list">
          <div v-if="display_show.length!=0">
            <div class="list-item m-b-12" :class="item.style" @click.stop="showDetail(item)" v-for="item,index in display_show" :key="index">
              <div class="list-item-content flex-c">
                <div class="err-word">{{item.error}}</div>
                <i class="el-icon-arrow-right"></i>
                <div class="edit-word" @click.stop="replaceChar(index)">{{item.correction}}</div>
              </div>
              <div class="list-item-detail flex-c" v-show="item.isShowd">
                <!-- <div class="m-r-32" @click.stop="itemAddWordBank(item)">加入词库</div> -->
                <el-dropdown class="addKeyWord m-l-4" trigger="click">
                  <span class="el-dropdown-link m-r-32 ignore" @click.stop="itemAddWordBank(item)">加入词库</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native.stop="openWordBank(1)">错词词库</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="openWordBank(2)">敏感词词库</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="ignore" @click.stop="ignore(index)">忽略</div>
              </div>
            </div>
          </div>
          <div v-else>
            恭喜你,文章没有错误了!!!
          </div>
        </div>

      </div>
    </div>
    <!-- 加入词库 -->
    <el-dialog :visible.sync="addWordBankVisible" width="27%">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">{{wordBankOpenTitle==1?'新增错词':'新增敏感词'}}</span>
      </div>
      <div class="dialog-body">
        <el-row :gutter="20" class="flex-c m-b-16">
          <el-col :span="6">
            <div>{{wordBankOpenTitle==1?'错词':'敏感词'}}</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="errWord"></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="flex-c m-b-16">
          <el-col :span="6">
            <div>建议修改为:</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="editWord"></el-input>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addWordBankVisible= false">取 消</el-button>
        <el-button type="primary" @click="submitWordBank">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
// import CKEditor from '@ckeditor/ckeditor5-vue2';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import '@ckeditor/ckeditor5-build-classic/build/translations/zh-cn';
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import "@/assets/css/editor.less"
import { checkContent } from "@/api/troubleshoot/index";
import {
  addKeyWord,
  importFile, exportFile
} from "@/api/myKeyWord/index";
export default {
  components: {
    quillEditor
    // ckeditor: CKEditor.component
  },
  data() {
    return {
      TypeShen: {},
      checkLoading: false,
      editorLoading: false,
      addWordBankVisible: false,
      wordBankOpenTitle: 1,
      errWord: '',
      editWord: '',
      display_show: [],
      all_show: [],
      high_probability: [],//高概率
      low_probability: [],//低概率
      custom: [],//自定义
      sensitive: [],//敏感词
      btnHasStyle_1: true,
      btnHasStyle_2: true,
      btnHasStyle_3: true,
      btnHasStyle_4: true,
      fontCount: 0,
      quill: null,//编辑器实力
      renderStr: '',
      content: `在中过供作是很紧账的,中国特色社会注意,习近平习近平习近平`,
      // content: `<p style='font-size:"36px"'>在中过供作是很紧账的</p>`,
      editorOption: {
        placeholder: '请输入',
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              // ['blockquote', 'code-block'], // 引用  代码块
              // [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              // [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              // [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
              [{ size: ["small", "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              // ['image'] // 链接、图片，需要视频的可以加上video
            ],
            // handlers: {   //此处是图片上传时候需要使用到的
            //   'image': function (value) {
            //     console.log(value)
            //     if (value) {  // 点击图片
            //       document.querySelector('#upload').click()
            //     }
            //   }
            // }
          },
          // imageDrop: true,   // 图片拖拽
          // imageResize: {     // 图片放大缩小
          //   displayStyles: {
          //     backgroundColor: "black",
          //     border: "none",
          //     color: "white"
          //   },
          //   modules: ["Resize", "DisplaySize", "Toolbar"]
          // }
        }
      },
    }
  },
  mounted() {
    const titleConfig = [
      { Choice: '.ql-insertMetric', title: '跳转配置' },
      { Choice: '.ql-bold', title: '加粗' },
      { Choice: '.ql-italic', title: '斜体' },
      { Choice: '.ql-underline', title: '下划线' },
      { Choice: '.ql-header', title: '段落格式' },
      { Choice: '.ql-strike', title: '删除线' },
      { Choice: '.ql-blockquote', title: '块引用' },
      { Choice: '.ql-code', title: '插入代码' },
      { Choice: '.ql-code-block', title: '插入代码段' },
      { Choice: '.ql-font', title: '字体' },
      { Choice: '.ql-size', title: '字体大小' },
      { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
      { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
      { Choice: '.ql-direction', title: '文本方向' },
      { Choice: '.ql-header[value="1"]', title: 'h1' },
      { Choice: '.ql-header[value="2"]', title: 'h2' },
      { Choice: '.ql-align', title: '对齐方式' },
      { Choice: '.ql-color', title: '字体颜色' },
      { Choice: '.ql-background', title: '背景颜色' },
      { Choice: '.ql-image', title: '图像' },
      { Choice: '.ql-video', title: '视频' },
      { Choice: '.ql-link', title: '添加链接' },
      { Choice: '.ql-formula', title: '插入公式' },
      { Choice: '.ql-clean', title: '清除字体格式' },
      { Choice: '.ql-script[value="sub"]', title: '下标' },
      { Choice: '.ql-script[value="super"]', title: '上标' },
      { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
      { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
      { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
      { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
      { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
      { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
      { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
      { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
      { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
      { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
      { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
      { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
      { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
      { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
      { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
      { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
      { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
      { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
    ]
    for (let item of titleConfig) {
      let tip = document.querySelector('.quill-editor ' + item.Choice)
      if (!tip) continue
      tip.setAttribute('title', item.title)
    }
    let that = this
    // 界面变化事件
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (this.display_show.length) {
          that.reHighlight(this.display_show);
          // console.log("界面变化了")
        }
      }
    });
    // 开始观察目标div元素的大小变化
    observer.observe(document.querySelector('.ql-container'));
    // 获取编辑器实例
    this.quill = this.$refs.myQuillEditor.quill
    // 绑定text-change事件

    this.quill.on("text-change", function (delta, oldDelta, source) {
      console.log(delta, oldDelta, source);
      if (source === "user") {
        that.handleTextChange(delta);
      }
    })


  },
  methods: {
    // 改变按钮组样式事件
    /**
     * 固定表述错误  1
     * 普通表述错误  2
     * 自定义        3
     * 敏感词        4
     */
    changeStyle(val) {
      // if( this.all_show.length == 0 || !this.all_show) return
      // const typeObj = {
      //   1: '固定表述错误',
      //   2: '普通表述错误'
      // }
      // if (typeObj[val] == this.TypeShen[val]) {
      //   delete this.TypeShen[val]
      // } else {
      //   this.TypeShen[val] = typeObj[val]
      // }
      // const TypeShenALl = Object.values(this.TypeShen) || [];
      // const duibi = (tips) => TypeShenALl.find(v => v == tips) || false;
      // this.display_show = TypeShenALl.length == 0 ? this.all_show : this.all_show.filter(v => duibi(v.tips)) || []
      // this[`btnHasStyle_${val}`] = !this[`btnHasStyle_${val}`]
      // this.changeDisplayList(val, this[`btnHasStyle_${val}`])
      if (this.all_show.length == 0) return
      switch (val) {
        case 1:
          this.btnHasStyle_1 = !this.btnHasStyle_1
          this.changeDisplayList(val, this.btnHasStyle_1)
          break;
        case 2:
          this.btnHasStyle_2 = !this.btnHasStyle_2
          this.changeDisplayList(val, this.btnHasStyle_2)
          break;
        case 3:
          this.btnHasStyle_3 = !this.btnHasStyle_3
          this.changeDisplayList(val, this.btnHasStyle_3)
          break;
        case 4:
          this.btnHasStyle_4 = !this.btnHasStyle_4
          this.changeDisplayList(val, this.btnHasStyle_4)
          break;
        default:
          break;
      }
    },
    // 改变展示项
    changeDisplayList(val, type) {
      if (!val) {
        this.display_show = this.all_show
        this.display_show = this.display_show.sort(function (a, b) {
          return a.position[0] - b.position[0];
        });
        this.display_show = this.display_show.map(v => {
          v.isShowd = false
          return v
        })
        return
      } else {
        if (this.display_show.length == this.all_show.length && !type) this.display_show = []
      }

      let obj = {
        1: 'high_probability',
        2: 'low_probability',
        3: 'custom',
        4: 'sensitive'
      }
      if (!type) {
        this.display_show = [...this.display_show, ...this[obj[val]]]
      } else {
        this.display_show = this.display_show.filter(item => !this[obj[val]].includes(item))
      }
      if (this.display_show.length == 0) this.display_show = this.all_show

      this.display_show = this.display_show.sort(function (a, b) {
        return a.position[0] - b.position[0];
      });
      this.display_show = this.display_show.map(v => {
        v.isShowd = false
        return v
      })
      this.reHighlight(this.display_show)//刷新高亮标签

    },
    // 立即校对
    async submit() {
      this.checkLoading = true
      console.log(this.content);
      let data = {
        "title": "",
        "content": this.renderStr || this.content,
        "special_misre": true,
        "ordinary_misre": true
      }
      let res = await checkContent(data)
      if (res.data.code == 200) {
        this.checkLoading = false
        // this.all_show = res.data.result.ordinary_misre_all.content_misre
        this.high_probability = res.data.result.special_misre_all.content_misre.map(v => {
          v.style = 'left-border-high'
          return v
        })
        this.low_probability = res.data.result.ordinary_misre_all.content_misre.map(v => {
          v.style = 'left-border-low'
          return v
        })
        this.custom = [].map(v => {
          v.style = 'left-border-custom'
          return v
        })
        this.sensitive = [].map(v => {
          v.style = 'left-border-sensitive'
          return v
        })
        this.all_show = [...this.high_probability, ...this.low_probability, ...this.custom, ...this.sensitive]


        //-------------------------------------
        this.changeDisplayList(0)
        //--------------------------------------
        // this.display_show = this.all_show
        // this.display_show = this.display_show.sort(function (a, b) {
        //   return a.position[0] - b.position[0];
        // });
        // this.display_show = this.display_show.map(v => {
        //   v.isShowd = false
        //   return v
        // })
        //-----------------------------------------



        const childDivs = document.querySelector('.ql-container').querySelectorAll('.highlight');
        console.log(childDivs);
        childDivs.forEach(childDiv => {
          document.querySelector('.ql-container').removeChild(childDiv);
        });
        /*
        this.quill.clipboard.dangerouslyPasteHTML(res.data.result.content) // 将html变成text  
        // 获取编辑器的内容
        const editorContent = this.quill.getContents()
        // 遍历内容中的每个段落 加下标 颜色
        editorContent.ops.forEach(op => {
          console.log(op)
          // 判断当前段落的文本是否包含需要添加下划线的关键词
          if (op.insert && op.attributes) {
            // 获取当前段落的位置
            // const startIndex = editorContent.ops.indexOf(op)
            const startIndex = this.quill.getText().indexOf(op.insert)
            const length = op.insert.length
            //  console.log(op)
            // console.log(length)
            // 格式化选区为下划线样式
            this.quill.formatText(startIndex, length, 'underline', true)
          }
        })
        return
        */
        // 获取到了要添加样式的文本及其下标区间后,调用渲染事件
        this.highlightText(this.display_show);
        // this.renderEditor()
      }
    },
    // 处理高亮文本,添加高亮文本划入划出事件,算出高亮文本边界
    highlightText(misre_list) {
      let styleType = "high";
      for (let i = 0; i < misre_list.length; i++) {
        let item = misre_list[i];
        let start_index = item.position[0];
        let end_index = item.position[1];
        let errLength = end_index - start_index;
        if (item.tips === "固定表述错误") {
          styleType = "high";
        } else if (item.tips === "普通表述错误") {
          styleType = "low";
        } else if (item.tips === "自定义错误") {
          styleType = "custom";
        } else if (item.tips === "敏感词") {
          styleType = "sensitive";
        }
        let start_bound = this.quill.getBounds(start_index, 1);
        let end_bound = this.quill.getBounds(end_index, 1)
        if (start_bound.bottom === end_bound.bottom) {
          let bounds = this.quill.getBounds(start_index, errLength);
          this.contentHighlight(bounds, i, styleType);
        } else {
          // console.log(`文本 "${err}" 跨行`);
          let nextIndex = start_index + 1;
          let nextCharBound = this.quill.getBounds(nextIndex, 1);
          while (start_bound.bottom === nextCharBound.bottom) {
            nextIndex += 1;
            nextCharBound = this.quill.getBounds(nextIndex, 1);
          }
          let nextLineLength = errLength - nextIndex + start_index;
          let markStartBounds = this.quill.getBounds(start_index, nextIndex - start_index);
          let markNextBounds = this.quill.getBounds(nextIndex, nextLineLength);
          this.contentHighlight(markStartBounds, i, styleType);
          this.contentHighlight(markNextBounds, i, styleType);
        }
        const divs = document.querySelectorAll(`[id="${i}"]`);

        divs.forEach(div => {
          div.addEventListener('mouseenter', () => {
            if (div.className.includes("high")) {
              divs.forEach(divClass => {
                divClass.classList.add('light-high');
              });
            } else if (div.className.includes("low")) {
              divs.forEach(divClass => {
                divClass.classList.add('light-low');
              });
            } else if (div.className.includes("custom")) {
              divs.forEach(divClass => {
                divClass.classList.add('light-custom');
              });
            } else if (div.className.includes("sensitive")) {
              divs.forEach(divClass => {
                divClass.classList.add('light-sensitive');
              });
            }
          });

          div.addEventListener('mouseleave', () => {
            if (div.className.includes("high")) {
              divs.forEach(divClass => {
                divClass.classList.remove('light-high');
              });
            } else if (div.className.includes("low")) {
              divs.forEach(divClass => {
                divClass.classList.remove('light-low');
              });
            } else if (div.className.includes("custom")) {
              divs.forEach(divClass => {
                divClass.classList.remove('light-custom');
              });
            } else if (div.className.includes("sensitive")) {
              divs.forEach(divClass => {
                divClass.classList.remove('light-sensitive');
              });
            }
          });
        })
      }
    },
    // 使用高亮文本边界值创建高亮div
    contentHighlight(bounds, id, styleType) {
      const highlightElement = document.createElement('div');
      if (styleType == "high") {
        highlightElement.className = 'highlight light-high-bottom';
      } else if (styleType == "low") {
        highlightElement.className = 'highlight light-low-bottom';
      } else if (styleType == "custom") {
        highlightElement.className = 'highlight light-custom-bottom';
      } else if (styleType == "sensitive") {
        highlightElement.className = 'highlight light-sensitive-bottom';
      }
      highlightElement.id = id;
      highlightElement.style.left = bounds.left + 'px';
      highlightElement.style.top = bounds.top + 'px';
      highlightElement.style.width = bounds.width + 'px';
      highlightElement.style.height = bounds.height + 'px';

      document.querySelector('.ql-container').appendChild(highlightElement);
    },
    // 编辑器内容变化时调用
    handleTextChange(delta) {
      // delta  变化的内容
      this.quill.focus();
      let range = this.quill.getSelection();
      console.log('delta', delta);
      console.log('range', range);
      let allOperates = {};
      for (let item of delta.ops) { //把delta.ops中的属性抽出来
        const key = Object.keys(item)[0];
        const value = item[key];
        allOperates[key] = value;
      }
      console.log(allOperates);
      // 如果range.index(文本变化的位置)
      if (this.display_show.length && range.index <= this.display_show[this.display_show.length - 1].position[1]) {
        let charLength = 0;
        let deleteNums = 0;
        let refresh = false;
        // 文本被删除
        if ("delete" in allOperates && !("insert" in allOperates)) {
          refresh = true;
          let start_index = range.index;
          let end_index = range.index + allOperates.delete;
          charLength = -allOperates.delete;
          console.log("开始下标", start_index, "结束下标", end_index, charLength)
          if (allOperates.delete == 1) { //删除一个字符
            for (let i = 0; i < this.display_show.length; i++) {
              let item = this.display_show[i];
              if (start_index >= item.position[0]) {
                if (end_index <= item.position[1]) {
                  deleteNums = 1;
                  break;
                }
              } else {
                break;
              }
            }
          } else { //删除一段字符串
            let deleteArray = []; //记录删除的下标和个数
            let deleteIndex = 0;
            for (let i = 0; i < this.display_show.length; i++) {
              let item = this.display_show[i];
              if (end_index > item.position[0] && start_index < item.position[1]) {
                deleteArray.push(i);
              } else if (start_index >= item.position[1]) {
                deleteIndex = i + 1;
              }
            }
            if (deleteArray.length) {
              i = deleteArray[0];
              deleteNums = deleteArray.length;
            } else {
              i = deleteIndex;
            }
          }
          for (let j = i; j < this.display_show.length; j++) {
            this.display_show[j].position[0] += charLength;
            this.display_show[j].position[1] += charLength;
          }
          if (deleteNums) {
            this.display_show.splice(i, deleteNums);
          }
        }
        else if ("insert" in allOperates) {
          refresh = true;
          deleteNums = 0;
          charLength = allOperates.insert.length;
          let insertLength = allOperates.insert.length
          if ("delete" in allOperates) {
            let deleteLength = allOperates.delete
            charLength = charLength - deleteLength
          }
          for (let i = 0; i < this.display_show.length; i++) {
            let item = this.display_show[i];
            if (range.index - insertLength > item.position[0]) {
              if (range.index <= item.position[1]) {
                // console.log("删除高亮")
                deleteNums = 1;
                break;
              } else if (i < this.display_show.length - 1 && range.index <= this.display_show[i + 1].position[0]) {
                // console.log("调整高亮位置")
                i++;
                break;
              }
            }
            else {
              // console.log("从头开始添加文字")
              break;
            }
          }
          for (let j = i; j < this.display_show.length; j++) {
            this.display_show[j].position[0] += charLength;
            this.display_show[j].position[1] += charLength;
          }
          if (deleteNums) {
            this.display_show.splice(i, 1);
          }
        }
      }
      if (refresh) {
        this.refreshMisreList();
        this.reHighlight(this.display_show);
      }
    },
    // 替换文本
    replaceChar(index) {
      let err = this.display_show[index].error
      let correct = this.display_show[index].correction
      let currIndex = this.display_show[index].position[0]
      // 需要更新的字数长度
      let updateLength = correct.length - err.length
      // 替换
      let cursorPosition = this.quill.getSelection()
      this.quill.deleteText(currIndex, err.length)
      this.quill.insertText(currIndex, correct)
      this.quill.setSelection(cursorPosition)
      this.quill.history.clear()
      // 删除展示列表中的数据
      this.display_show.splice(index, 1)
      this.refreshMisreList()
      // 替换之后保证其他高亮正常
      this.display_show = this.display_show.map(v => {
        // 只改变替换文本所在位置之后的其他文本位置
        if (v.position[1] > currIndex) {
          let modifiedValues = v.position.map(item => item * 1 + updateLength * 1)
          console.log(modifiedValues);
          return { ...v, position: modifiedValues }
        } else {
          return v
        }

      })
      // 重新刷新高亮标签
      this.reHighlight(this.display_show)
    },
    //更新显示的错误列表
    refreshMisreList() {
      let high_probability = []
      let low_probability = []
      let custom = []
      let sensitive = []
      this.display_show.forEach(v => {
        if (v.tips == "固定表述错误") {
          high_probability.push(v)
        } else if (v.tips == "普通表述错误") {
          low_probability.push(v)
        } else if (v.tips == "自定义错误") {
          custom.push(v)
        } else if (v.tips == "敏感词") {
          sensitive.push(v)
        }
      })
      if (!this.high_probability.length || high_probability) {
        this.high_probability = high_probability
      }
      if (!this.low_probability.length || low_probability) {
        this.low_probability = low_probability
      }
      if (!this.custom.length || custom) {
        this.custom = custom
      }
      if (!this.sensitive || sensitive) {
        this.sensitive = sensitive
      }
      return
    },
    // 刷新高亮
    reHighlight(misre_list) {
      const childDivs = document.querySelector('.ql-container').querySelectorAll('.highlight');
      childDivs.forEach(childDiv => {
        childDiv.parentNode.removeChild(childDiv);
      });
      this.highlightText(misre_list);
    },
    // 重新渲染编辑器
    renderEditor() {

    },
    // 内容改变事件，只需要这一个方法就够了
    onEditorChange({ quill, html, text }) {

      console.log('editor change!', quill, html, text)
      console.log('text', text)
      // this.content = html
      this.fontCount = text.replace(/\s/g, "").length
      this.renderStr = text
    },
    itemAddWordBank(item) {
      console.log(item);
      this.errWord = item.error
      this.editWord = item.correction
    },
    // 忽略
    ignore(index) {
      this.display_show.splice(index, 1)
      this.refreshMisreList();
      this.reHighlight(this.display_show);
    },
    // 加入词库
    addWordBank() {
      let range = this.$refs.myQuillEditor.quill.getSelection(true);
      console.log(range);
      let delta = this.$refs.myQuillEditor.quill.getContents(
        range.index,
        range.length
      );
      if (delta.ops[0]) this.errWord = delta.ops[0].insert
    },
    // 打开词库弹框
    openWordBank(val) {
      this.wordBankOpenTitle = val
      this.addWordBankVisible = true
    },
    async submitWordBank() {
      let data = [{
        orgId: JSON.parse(localStorage.getItem('user')).orgId,
        type: this.wordBankOpenTitle,//错误词类型（1.错误词，2敏感词）
        inaccuracyWord: this.errWord,//错误词
        correctWord: this.editWord,//建议更正
      }]
      if (data[0].inaccuracyWord == '' || data[0].correctWord == '') {
        this.$message.warning('您输入的信息不完整')
        return
      }
      let res = await addKeyWord(data)
      if (res.data.code == 200) {
        this.$message.success('添加成功')
        this.errWord = ''
        this.editWord = ''
      }
      this.addWordBankVisible = false
    },
    // 我的词库
    goDetail(val) {
      console.log(val);
      this.$router.push({
        path: '/myKeyWord',
        query: { type: val }
      })
    },
    // 上传文件
    async uploadFile(file) {
      console.log(file);
      let data = file.file
      let formData = new FormData();
      formData.append("file", data);
      this.editorLoading = true
      let res = await importFile(formData)
      if (res.data.code == 200) {
        this.editorLoading = false
        this.content = res.data.data.data.replace(/\n/g, '')
      }
    },
    //导出文件
    async exportFile() {
      let data = {
        html: this.content,
        type: 'docx',
        fileName: '已排查文件'
      }
      let res = await exportFile(data)
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(res.data) // 创建下载的链接
      downloadElement.href = href
      downloadElement.download = decodeURI('已排查文件.docx') // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
    },
    // 右侧的错误列表item点击事件
    showDetail(item) {
      console.log(item);
      item.isShowd = !item.isShowd
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .page-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 24px 0;
    .title-name {
      font-size: 26px;
      font-weight: 600;
      color: #1a1a1a;
    }
  }
  .content-card {
    display: flex;
    width: 100%;
    height: 740px;
    padding: 24px;
    background: #ffffff;
    border-radius: 4px;
    .content-right {
      width: calc(100% - 424px);
      height: 100%;
      margin-right: 24px;
      .editor-btn-group {
        position: relative;
        display: flex;
        border: #ccc 1px solid;
        border-bottom: 0;
        padding: 10px;
        font-size: 14px;
        .fontCont {
          position: absolute;
          right: 16px;
          color: #999;
        }
        div {
          margin-right: 32px;
          cursor: pointer;
        }
        .addKeyWord {
          color: #3078ff;
        }
        .myKeyWord {
          color: #f58030;
        }
      }
      .editor {
      }
    }
    .content-left {
      width: 400px;

      .btn-group {
        width: 100%;
        height: auto;
        padding: 25px;
        background: #f5f6fa;
        border-radius: 4px;
        font-size: 14px;
        .jiaodui {
          width: 350px;
          height: 36px;
          background: #f58030;
          border-radius: 4px;
          text-align: center;
          line-height: 36px;
          color: #ffffff;
          cursor: pointer;
        }
        .btn-type {
          display: flex;
          justify-content: space-between;
          width: 350px;
          height: 80px;
          background: #ffffff;
          border-radius: 4px;
          .tips-all {
            width: 80px;
            // padding: 20px 16px;
            // padding-right: 0;
            color: #f58030;
          }
          .tips-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            width: 270px;
            height: 100%;
            // padding: 20px 16px;
            // padding-left: 0;
            div {
              width: 76px;
              height: 28px;
              border-radius: 4px;
              text-align: center;
              line-height: 28px;
              // margin-right: 16px;
              // margin-bottom: 8px;
            }
            .tip-red {
              background: rgba(230, 71, 89, 0.1);
              color: #ea3342;
            }
            .tip-orange {
              background: rgba(254, 176, 59, 0.1);
              color: #f58030;
            }
            .tip-green {
              background: rgba(19, 205, 82, 0.1);
              color: #02bc7c;
            }
            .tip-blue {
              background: rgba(97, 191, 232, 0.1);
              color: #3078ff;
            }
            .no-background {
              background: #fff;
            }
          }
        }
      }
      .display-list {
        width: 100%;
        height: 500px;
        overflow: auto;
        padding: 25px;
        background: #f5f6fa;
        border-radius: 4px;
        .list-item {
          width: 100%;
          height: auto;
          padding: 16px 24px;
          border-radius: 4px;
          background: #fff;
          border-left: rgba(230, 71, 89, 0.6) 4px solid;
          .list-item-content {
            display: flex;
            .err-word {
              cursor: default;
            }
            .edit-word {
              padding: 4px;
              cursor: pointer;
              &:hover {
                background: rgba(46, 112, 222, 0.1);
              }
            }
          }
          .list-item-detail {
            // display: none;
            padding-top: 16px;
            .ignore {
              font-size: 14px;
              color: #2e70de;
              cursor: pointer;
              padding: 4px;
              border-radius: 2px;
              &:hover {
                background: rgba(46, 112, 222, 0.1);
              }
            }
          }
        }
        .left-border-high {
          border-left: rgba(230, 71, 89, 0.6) 4px solid;
          &:hover {
            background: #ffd3d7;
          }
        }
        .left-border-low {
          border-left: rgba(254, 176, 59, 0.6) 4px solid;
          &:hover {
            background: #ffd0b0;
          }
        }
        .left-border-custom {
          border-left: rgba(19, 205, 82, 0.6) 4px solid;
          &:hover {
            background: #caf2e4;
          }
        }
        .left-border-sensitive {
          border-left: rgba(97, 191, 232, 0.6) 4px solid;
          &:hover {
            background: #c8dbff;
          }
        }
      }
    }
  }
}
::v-deep .ql-editor {
  overflow-y: unset !important;
}
::v-deep .ql-container {
  // min-height: 620px;
  height: 620px;
  overflow: auto;
  // 富文本高亮样式
  .highlight {
    position: absolute;
    &::before {
      content: "" !important;
      position: absolute !important;
      width: 100% !important;
      left: 0 !important;
      height: 100% !important;
      top: 0 !important;
    }
    &::after {
      visibility: visible !important;
      bottom: -2px !important;
      height: 2px !important;
      border-radius: 10px !important;
      content: "" !important;
      position: absolute !important;
      width: 100%;
      left: 0 !important;
    }
  }

  .light-sensitive {
    position: absolute;
  }
  .light-sensitive::before {
    background: rgba(97, 191, 232, 0.6);
  }
  .light-sensitive-bottom {
    position: absolute;
  }
  .light-sensitive-bottom::after {
    background: #c8dbff;
  }

  .light-custom {
    position: absolute;
  }
  .light-custom::before {
    background: rgba(19, 205, 82, 0.6);
  }
  .light-custom-bottom {
    position: absolute;
  }
  .light-custom-bottom::after {
    background: #caf2e4;
  }

  .light-low {
    position: absolute;
  }
  .light-low::before {
    background: rgba(254, 176, 59, 0.6);
  }
  .light-low-bottom {
    position: absolute;
  }
  .light-low-bottom::after {
    background: #ffd0b0;
  }

  .light-high {
    position: absolute;
  }
  .light-high::before {
    background: rgba(230, 71, 89, 0.2);
  }
  .light-high-bottom {
    position: absolute;
  }
  .light-high-bottom::after {
    background: #e64759;
  }
}
</style>